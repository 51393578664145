import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

// s4n
import { injectIntl, Link } from 'gatsby-plugin-intl'
// import '../assets/sass/components/_timeline.scss'
// import '../assets/sass/components/_team.scss'
import ImageS4n from './../image'
// @todo: create <Person /> component
import kebabCase from "lodash.kebabcase"
import GeneralUtils from './../../../utils/common'
import Obfuscate from 'react-obfuscate';



const ComponentStateless = (props) => {
    const { intl } = props;
    const t = intl.formatMessage;

    const team = [
        {
            name: `Mateusz Poźniak`,
            img: `custom/team/mateusz_pozniak.jpg`,
            email: `mateusz@soft4net.io`,
            url: undefined,
            position: {
                pl: `Senior Full-Stack Deweloper`,
                en: `Senior Full-Stack Developer`,
            },
        },
        {
            name: `Piotr Lepik`,
            img: `custom/team/piotr_lepik.jpg`,
            email: `piotr@soft4net.io`,
            url: undefined,
            position: {
                pl: `Senior Deweloper`,
                en: `Senior Developer`,
            },
        },
        {
            name: `Katarzyna Zięba`,
            img: `custom/team/katarzyna_zieba.jpg`,
            email: `katarzyna@soft4net.io`,
            url: undefined,
            position: {
                pl: `Office Manager`,
                en: `Office Manager`,
            },
        },
        {
            name: `Paweł Poźniak`,
            img: `custom/team/pawel_pozniak.jpg`,
            email: `pawel@soft4net.io`,
            url: undefined,
            position: {
                pl: `SEO / SEM`,
                en: `SEO / SEM`,
            },
        },
        {
            name: `Kamil Żbikowski`,
            img: `custom/team/kamil_zbikowski.jpg`,
            email: `kamil@soft4net.io`,
            url: undefined,
            position: {
                pl: `Kierownik Sprzedaży`,
                en: `Sales Manager`,
            },
        },
        // {
        //     name: `Katarzyna Zięba`,
        //     img: `custom/team/katarzyna_zieba.jpg`,
        //     email: `katarzyna@soft4net.io`,
        //     url: undefined,
        //     position: {
        //         pl: `Kierownik biura`,
        //         en: `Office Manager`,
        //     },
        // },
        // {
        //     name: `Paweł Skuza`,
        //     img: `custom/team/pawel_skuza.jpg`,
        //     url: undefined,
        //     position: `Developer`,
        // },
        // {
        //     name: `Adrian Suchan`,
        //     img: `custom/team/adrian_suchan.jpg`,
        //     url: undefined,
        //     position: `Developer`,
        // },
        // {
        //     name: `Paweł Augustyński`,
        //     img: `custom/team/pawel_augustynski.jpg`,
        //     url: undefined,
        //     position: `Junior Developer`,
        // },
        // {
        //     name: `Tosia`,
        //     img: `custom/team/tosia.jpg`,
        //     url: undefined,
        //     position: `Full-Stack Junior Cat`,
        // },
    ];

    return (
        <React.Fragment>

            <section className="section bg-cover s4n-team" 
                // data-background="images/backgrounds/team-bg.png"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2 className="section-title">{intl.locale === `en` ? `Our team` : `Nasz zespół`}</h2>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        {/* @see: https://hackernoon.com/5-techniques-to-iterate-over-javascript-object-entries-and-their-performance-6602dcb708a8 */}
                        {team.map((person, index) => {

                            // const url = `/${kebabCase(person.name)}`;
                            const url = person.url ? `/${person.url}` : undefined;

                            return (
                                <div key={person.name} className="col-md-6 col-lg-4 col-xl-3 mb-4">
                                    <div className="card shadow text-center">
                                        {/* <Link to={url}> */}
                                            { person.img ? 
                                                <ImageS4n src={ person.img } alt={`${person.name} - ${person.position[intl.locale]}`} className="card-img-top" _maxWidth="103px" />
                                                :
                                                <i className="fas fa-user"></i>
                                            }
                                        {/* </Link> */}
                                        <div className="card-body">
                                            <h4 className="card-title">
                                                {/* <Link to={url}> */}
                                                    { person.name }
                                                {/* </Link> */}
                                            </h4>
                                            <p className="text-white paragraph-lg font-secondary">
                                                {/* <a data-encoded={GeneralUtils.rot13(person.email)} href="mailto:">{GeneralUtils.rot13(person.email)}</a> */}
                                                <Obfuscate email={person.email} />
                                            </p>
                                            <p className="text-light font-secondary">{person.position[intl.locale]}</p>
                                        </div>

                                        {/* <p className="summary" dangerouslySetInnerHTML={{ __html: person.body ? person.body.summary : null }}></p> */}

                                        {/* <ul className="social-media">
                                            {person.field_social_media_links[0] && 
                                                <li><a href={`mailto:${person.field_social_media_links[0]}`} className="email"><i className="fas fa-envelope"></i></a></li>}
                                            {person.field_social_media_links[1] && 
                                                <li><a href={person.field_social_media_links[1]} target="_blank" className="facebook"><i className="fab fa-facebook"></i></a></li>}
                                            {person.field_social_media_links[2] && 
                                                <li><a href={person.field_social_media_links[2]} target="_blank" className="twitter"><i className="fab fa-twitter"></i></a></li>}
                                            {person.field_social_media_links[3] && 
                                                <li><a href={person.field_social_media_links[3]} target="_blank" className="dribbble"><i className="fab fa-instagram"></i></a></li>}
                                        </ul> */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </React.Fragment>        
    )
}

export default injectIntl(ComponentStateless)